import React from 'react'
import rocket from '../assets/background/rocket.svg'
import hexes2 from '../assets/background/hexes2.svg'
import '../App.css'
import { useWindowSize } from './hooks/useWindowSize';



export default function ChooseUs() {

    const size = useWindowSize();


    return (
        <div className="choose_us-wrapper">

            <div className='flex md:mt-56 mt-0 justify-center bg-[#FFFFFF0A] w-full p-10 2xl:h-[700px] md:h-[700px] backdrop-filter-blur'>
                
                <div className=' w-[1400px] flex justify-between items-center '>
                    <div className='flex flex-col gap-10 md:w-1/3 w-full'>
                    {size.width < 768 ?
                    <div className='w-full flex flex-col justify-between'>
                        <img src={rocket} className='hero_pepe' />
                    </div>
                    : ''}
                        <div className='font-bold text-4xl md:text-left text-center'>Why choose us?</div>
                        <div className='text-xl md:text-left text-center'>
                            <p>Choose $SPEPE for an exhilarating casino experience that combines the thrill of gaming with the speed and innovation of the Solana blockchain. With a diverse mix of emoji games—ranging from slots to sports-themed bets—you'll have endless opportunities to play, win, and earn real rewards while having fun.</p>
                            <br />
                            <p>What sets us apart? We're not just another casino; we're a community-driven platform that prioritizes fairness and transparency. All games use Telegram’s API to ensure random outcomes, avoiding any manipulation often found in traditional casinos. Plus, with fast, secure transactions and low fees on the Solana network, you can enjoy a seamless and stress-free experience.</p>
                        </div>
                    </div>
                    {size.width > 768 ?
                        <div className='w-2/3 flex flex-col justify-between'>
                            <img src={rocket} className='hero_pepe' />
                        </div>
                        : ''}


                </div>
            </div>
            <div className="absolute top-20 right-0 -z-50">
                <img src={hexes2} alt="Dots Circle" className="w-auto h-auto max-w-none transform translate-x-100%" />
            </div>
        </div>
    )
}
