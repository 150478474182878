import React from 'react'
import slots from '../assets/games/slots.svg'
import dice from '../assets/games/dice.png'
import basket from '../assets/games/basket.svg'
import bowling from '../assets/games/bowling.svg'
import football from '../assets/games/football.svg'
import dartsSVG from '../assets/games/darts.svg'
import dots_circle from '../assets/background/dots_circle.svg'
import hexes from '../assets/background/hexes.svg'


export default function GamesSection() {

    const games = [
        {
            title: 'SLOTS',
            desc: 'Spin the reels and match symbols to win! Land 7️⃣7️⃣ for a small payout or hit 7️⃣7️⃣7️⃣ for a big win. It\'s all about luck—good spins lead to epic rewards!',
            img: slots
        },
        {
            title: 'Dice',
            desc: `Ready to roll the dice? Roll a 4️⃣, 5️⃣, or 6️⃣ to win. Place your bet, make your roll, and see if luck is on your side. It’s simple, exciting, and full of surprises!`,
            img: dice
        },
        {
            title: 'Basket',
            desc: 'Shoot your shot! You can earn in 2 ways: hit a "Splash shot" or simply make a "Shot" to win. Place your bet, take aim, and see if you can make it count. The outcome is all about your luck, so give it your best throw!',
            img: basket
        },
        {
            title: 'Bowling',
            desc: 'Strike! Here you roll the ball and aim to knock down all the pins. You can win in 2 ways: score a "Strike" for a big win, or leave just one pin standing for a smaller reward. Take your shot and see how many pins you can knock down!',
            img: bowling
        },
        {
            title: 'Darts',
            desc: 'Take aim and throw! Here your winnings depend on how close you hit to the center. Land a shot into the center for the top reward, or get close by hitting 1 circle away or 2 circles away from the bullseye for smaller wins. Ready to test your accuracy?',
            img: dartsSVG
        },
        {
            title: 'Football',
            desc: `Score a goal and win! This emoji game it's very simple: take your shot and aim for the net. If you score, you win! It's all about precision and a bit of luck. Are you ready to kick off?`,
            img: football
        },
      
    ]

    return (
<div className="wrapper 2xl:pb-0 pb-56">
        <div className="content-container" style={{ height: '100%' }}>
            <div className='flex justify-between flex-col mt-24'>
                <div className='flex justify-center mb-3 text-4xl font-semibold'>SPEPE Games</div>
                <div className='flex justify-center text-base text-center'>While we are still fine-tuning how the rewards will be distributed to players, <br /> we've already established the mechanics for how you can win in each game</div>
                <div className='flex flex-wrap md:flex-row flex-col gap-3 mt-12'>
                    {games.map((el, i) => (
                        <div className='backdrop-filter-blur border md:w-[32.5%] w-full border-[#4D9636] rounded-2xl bg-[#FFFFFF0D] p-12'>
                            <div className='rounded-3xl bg-[#FFFFFF0D]  mb-10'>
                                <img src={el.img} className='w-full rounded-3xl'/>
                            </div>
                            <div className='flex justify-center mb-1 text-3xl font-semibold'>{el.title}</div>
                            <div className='text-[#FFFFFF99] text-center font-medium'>{el.desc}</div>
                        </div>
                    ))}
                </div>
            </div>
            <div className="absolute top-20 right-0 -z-50">
                <img src={dots_circle} alt="Dots Circle" className="w-auto h-auto max-w-none transform translate-x-100%" />
            </div>
            <div className="absolute bottom-40 left-0 -z-50">
                <img src={hexes} alt="Dots Circle" className="w-auto h-auto max-w-none transform translate-x-100%" />
            </div>
        </div>
    </div>
    )
}
