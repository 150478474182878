import logo from './logo.svg';
import './App.css';
import Header from './components/Header';
import HeroSection from './components/HeroSection';
import Footer from './components/Footer';
import GamesSection from './components/GamesSection';
import ChooseUs from './components/ChooseUs';
import RoadmapSection from './components/RoadmapSection';
import TokenomicsSection from './components/TokenomicsSection';
import Tokenomics2Section from './components/Tokenomics2Section';
import WinInGameSection from './components/WinInGameSection';
import TeamSection from './components/TeamSection';
import FAQSection from './components/FAQSection';

function App() {
  return (
    <>
      <Header/>
      <HeroSection/>
      <GamesSection/>
      <ChooseUs/>
      <RoadmapSection/>
      <TokenomicsSection/>
      <Tokenomics2Section/>
      <WinInGameSection/>
      <TeamSection/>
      <FAQSection/>
      <Footer/>
    </>
  );
}

export default App;
