import React, { useState } from 'react'

import dots_circle from '../assets/background/dots_circle.svg'
import simple_circle from '../assets/background/simple_circle.svg'
import star from '../assets/background/star.svg'
import { Accordion, AccordionItem as Item } from "@szhsin/react-accordion";
import chevron from "../assets/chevron-down.svg";


const AccordionItem = ({ header, ...rest }) => (
    <Item
        {...rest}
        header={({ state: { isEnter } }) => (
            <>
                {header}
                <img
                    className={`ml-auto transition-transform duration-200 ease-out ${isEnter && "rotate-180"
                        }`}
                    src={chevron}
                    alt="Chevron"
                />
            </>
        )}
        className="border border-[#6BF3404A] rounded-3xl mb-4"
        buttonProps={{
            className: ({ isEnter }) =>
                `flex w-full p-4 text-left rounded-3xl hover:bg-[#6BF3404A] ${isEnter && "bg-[#6BF3404A]  rounded-br-none rounded-bl-none"
                }`
        }}
        contentProps={{
            className: "transition-height duration-200 ease-out"
        }}
        panelProps={{ className: "p-4" }}
    />
);

export default function FAQSection() {



    return (
        <div className="wrapper mt-56">
            <div className="content-container" style={{ height: '100%' }}>
                <div className='flex justify-between flex-col mt-24'>
                    <div className='flex justify-center mb-3 text-4xl font-semibold'>Frequently Asked Questions</div>
                </div>
                <div className='px-[5%] mt-16'>
                    <Accordion allowMultiple transition transitionTimeout={200}>
                        <AccordionItem header="What is the SPEPE token?" >
                            SPEPE is a Solana-based meme token designed for our unique Telegram casino. It’s used for placing bets in various emoji-based games, allowing players to win real rewards in a fair and community-driven gaming environment.
                        </AccordionItem>

                        <AccordionItem header="How will the casino work?">
                            Our casino will host six different emoji games, where the results are determined by the Telegram API, ensuring fairness and unpredictability. We haven't finalized the reward system yet, but we've already decided on the mechanics for winning in each game. Stay tuned for updates on how rewards will be distributed!
                        </AccordionItem>

                        <AccordionItem header="How many presales will there be?">
                            There will be three presale stages, each with different amounts of SPEPE tokens available and buy prices.
                        </AccordionItem>

                        <AccordionItem header="In what tokens can I buy SPEPE during the presale?">
                            Currently, you can purchase SPEPE using SOL and USDT. We plan to add more tokens in the future to make the presale even more accessible.                        </AccordionItem>

                        <AccordionItem header="When will the casino launch?">
                            The casino beta will be launched in the first stage of our presale. During the beta, you’ll be able to explore the casino, but gameplay will be available without using tokens initially. This allows us to fine-tune the experience and the rewards system before the full launch.
                        </AccordionItem>
                        </Accordion>
                        </div>


                    <div className="absolute top-20 rotate-45 right-12 -z-50">
                        <img src={simple_circle} alt="Dots Circle" className="w-auto h-auto max-w-none transform translate-x-100%" />
                    </div>
                    <div className="absolute top-0 rotate-45 left-0 -z-50">
                        <img src={dots_circle} alt="Dots Circle" className="w-auto h-auto max-w-none transform translate-x-100%" />
                    </div>
                    <div className="absolute bottom-40 left-10 -z-50">
                        <img src={simple_circle} alt="Dots Circle" className="w-auto h-auto max-w-none transform translate-x-100%" />
                    </div>
                    <div className="absolute bottom-30 right-20 rotate-45 -z-50">
                        <img src={star} alt="Dots Circle" className="w-12 h-auto max-w-none transform translate-x-100%" />
                    </div>
                    <div className="absolute bottom-10 right-10 -z-50">
                        <img src={star} alt="Dots Circle" className="w-auto h-auto max-w-none transform translate-x-100%" />
                    </div>
                </div>
            </div>
            )
}
