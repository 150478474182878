import React from 'react'

import roadmap from '../assets/roadmap.svg'
import roadmap_mobile from '../assets/roadmap_mobile.svg'
import dots_circle from '../assets/background/dots_circle.svg'
import hexes from '../assets/background/hexes.svg'
import { useWindowSize } from './hooks/useWindowSize'


export default function RoadmapSection() {

    const size = useWindowSize();


    return (
        <div className="wrapper 2xl:pb-0 md:pb-56 pb-96">
            <div className="content-container md:h-[120vh] h-full" >
                <div className='flex justify-between flex-col mt-24'>
                    <div className='flex justify-center mb-3 text-4xl font-semibold'>Road map 2024</div>
                    <div className='flex justify-center text-base text-center'>The roadmap is divided into three stages, each focusing on growth, community engagement, and platform development.</div>
                </div>
                <div className='flex justify-center mt-24'>
                    <img src={size.width > 768 ? roadmap : roadmap_mobile}/>
                </div>

                <div className="absolute top-20 right-0 -z-50">
                    <img src={dots_circle} alt="Dots Circle" className="w-auto h-auto max-w-none transform translate-x-100%" />
                </div>
                <div className="absolute bottom-40 left-0 -z-50">
                    <img src={hexes} alt="Dots Circle" className="w-auto h-auto max-w-none transform translate-x-100%" />
                </div>
            </div>
        </div>
    )
}
