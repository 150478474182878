import React from 'react'
import token from '../assets/logo.svg'
import { FaTwitter, FaTelegramPlane } from 'react-icons/fa';

export default function Footer() {
  return (
    <div className='wrapper'>
      <div className="content-container flex md:flex-row flex-col md:gap-36 gap-8 justify-between mt-56 mb-12">
        <div className='flex flex-col gap-5'>
          <div className='font-semibold text-xl'>Navigation</div>
          <hr className='md:w-[250px] w-full border-[#7F7F7F]' />
          <div>
              <a href="https://whitepaper.spepe.app/spepe-community/how-to-buy" target="_blank" rel="noopener noreferrer">
              How To Buy
              </a>
          </div>
          <div>
              <a href="https://whitepaper.spepe.app" target="_blank" rel="noopener noreferrer">
              Whitepaper
              </a>
          </div>
          <div>
              <a href="https://presale.spepe.app" target="_blank" rel="noopener noreferrer">
                  Join Mpepe Presale
              </a>
          </div>
        </div>
        <div>
          <img src={token} className='w-96' />
        </div>
        <div className='flex flex-col gap-5'>
          <div className='font-semibold text-xl'>Contact Us</div>
          <hr className='md:w-[250px] w-full border-[#7F7F7F]' />
          <div>
          <a href="mailto:team@spepe.app">Email: team@spepe.app</a>
          </div>
          <div className='font-semibold text-xl'>Our Socials</div>
          <hr className='md:w-[250px] w-full border-[#7F7F7F]' />
          <div className='flex gap-3'>


            <div>
              <a href="https://x.com/SPEPEcasino" target="_blank" rel="noopener noreferrer">
                <FaTwitter size={24} />
              </a>
            </div>
            <div>
              <a href="https://t.me/spepecommunity" target="_blank" rel="noopener noreferrer">
                <FaTelegramPlane size={24} />
              </a>
            </div>
          </div>
        </div>

      </div>
    </div>
  )
}
