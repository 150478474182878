import React from 'react'
import logo from '../assets/logo.svg'
import '../App.css'
import { useWindowSize } from './hooks/useWindowSize'

export default function Header() {

    const size = useWindowSize();

    console.log('size', size)

    return (
        <header className="flex justify-center max-w-[1400px] mx-auto px-4 pt-4">
            <div className="w-full flex items-center gap-12">
                <div className='flex items-center font-bold text-2xl gap-1'>
                    <img src={logo} /> $SPEPE
                </div>
                {size.width < 768 ? '' :
               ''
                }
            </div>
            <div className='outline_button ml-4'>
                <a href="https://presale.spepe.app" target="_blank" rel="noopener noreferrer">Buy $SPEPE</a>
            </div>
        </header>
    )
}
