import React, { useState } from 'react'

import dots_circle from '../assets/background/dots_circle.svg'
import simple_circle from '../assets/background/simple_circle.svg'
import { useWindowSize } from './hooks/useWindowSize';


export default function TokenomicsSection() {

    const size = useWindowSize();


    const [isCopied, setIsCopied] = useState(false);

    async function copyTextToClipboard(text) {
        if ('clipboard' in navigator) {
            return await navigator.clipboard.writeText(text);
        } else {
            return document.execCommand('copy', true, text);
        }
    }

    const handleCopyClick = () => {
        copyTextToClipboard('J8gSjeUBHkVpXexgT1h9qWK3koPLcoQ87ywzrVLBi5CM')
            .then(() => {
                setIsCopied(true);
                setTimeout(() => {
                    setIsCopied(false);
                }, 1500);
            })
            .catch((err) => {
                console.log(err);
            });
    }

    return (
        <div className="wrapper 2xl:pb-0 md:pb-56 pb-0">
            <div className="content-container" style={{ height: '40vh' }}>
                <div className='flex justify-between flex-col md:mt-24 mt-6'>
                    <div className='flex justify-center mb-3 text-4xl font-semibold'>Tokenomics</div>
                    <div className='flex justify-center text-base text-center'>$SPEPE Token Address</div>
                </div>
                <div className='flex justify-center mt-16'>
                    <div className='bg-[#ACACAC1A] md:text-base text-[10px] rounded-full rounded-r-none p-4 relative'>
                        J8gSjeUBHkVpXexgT1h9qWK3koPLcoQ87ywzrVLBi5CM
                    </div>
                    <div onClick={handleCopyClick} className='bg-[#4D9636] hover:bg-[#62be45] md:text-base text-[10px] cursor-pointer rounded-full rounded-l-none p-4 flex items-center gap-2 font-medium'>
                        {isCopied ? 'Copied!' : 'Copy'}
                        <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0_11_2683)">
                                <g clip-path="url(#clip1_11_2683)">
                                    <mask id="mask0_11_2683" maskUnits="userSpaceOnUse" x="0" y="1" width="17" height="16">
                                        <path d="M16.0801 1.39502H0.0800781V16.6677H16.0801V1.39502Z" fill="white" />
                                    </mask>
                                    <g mask="url(#mask0_11_2683)">
                                        <mask id="mask1_11_2683" maskUnits="userSpaceOnUse" x="0" y="1" width="17" height="16">
                                            <path d="M16.0207 1.70947H0.295898V16.648H16.0207V1.70947Z" fill="white" />
                                        </mask>
                                        <g mask="url(#mask1_11_2683)">
                                            <path d="M10.839 4.92627H3.04807C2.17961 4.92627 1.47559 5.63029 1.47559 6.49875V14.2897C1.47559 15.1582 2.17961 15.8622 3.04807 15.8622H10.839C11.7075 15.8622 12.4115 15.1582 12.4115 14.2897V6.49875C12.4115 5.63029 11.7075 4.92627 10.839 4.92627Z" stroke="white" stroke-width="1.57248" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M3.90527 2.49609H13.2688C14.1371 2.49609 14.8412 3.20011 14.8412 4.06857V13.432" stroke="white" stroke-width="1.57248" stroke-linecap="round" stroke-linejoin="round" />
                                        </g>
                                    </g>
                                </g>
                            </g>
                            <defs>
                                <clipPath id="clip0_11_2683">
                                    <rect width="17" height="17" fill="white" transform="translate(0 0.451172)" />
                                </clipPath>
                                <clipPath id="clip1_11_2683">
                                    <rect width="17" height="17" fill="white" transform="translate(0 0.451172)" />
                                </clipPath>
                            </defs>
                        </svg>
                    </div>

                </div>

                <div className="absolute top-20 right-12 -z-50">
                    <img src={simple_circle} alt="Dots Circle" className="w-auto h-auto max-w-none transform translate-x-100%" />
                </div>

                <div className="absolute top-20 right-12 -z-50">
                    <img src={dots_circle} alt="Dots Circle" className="w-auto h-auto max-w-none transform translate-x-100%" />
                </div>
                {size.width < 768 ? "" :
                    <div className="absolute bottom-40 left-10 -z-50">
                        <img src={simple_circle} alt="Dots Circle" className="w-auto h-auto max-w-none transform translate-x-100%" />
                    </div>
                }

            </div>
        </div>
    )
}
