import React from 'react'
import rocket from '../assets/background/rocket.svg'
import hexes2 from '../assets/background/hexes2.svg'
import SPEPETokenomics from '../assets/SPEPETokenomics.svg'
import '../App.css'



export default function Tokenomics2Section() {



    return (
        <div className="tokenomics-wrapper">
            <div className='md:mt-32 mt-10 flex flex-col items-center justify-center bg-[#FFFFFF0A] w-full md:p-10 p-0 2xl:h-[800px] md:h-[440px] h-full backdrop-filter-blur'>

                <div className='flex justify-center items-center'>
                    <img src={SPEPETokenomics} className='hero_pepe 2xl:h-[550px] h-[300px]' />
                </div>
                <div className='tokenomics_grid md:w-auto w-1/2 grid md:grid-cols-5 md:grid-rows-1 grid-rows-5 md:divide-x divide-y md:divide-y-[0px] divide-[#6BF3404A] border border-[#6BF3404A] rounded-3xl'>
                    <div className='flex flex-col gap-2 font-bold text-sm p-5'>
                        <div>Symbol:</div>
                        <div>$SPEPE</div>
                    </div>
                    <div className='flex flex-col gap-2 font-bold text-sm p-5'>
                        <div>Supply:</div>
                        <div>10,000,000,000</div>
                    </div>
                    <div className='flex flex-col gap-2 font-bold text-sm p-5'>
                        <div>Network:</div>
                        <div>Solana</div>
                    </div>
                    <div className='flex flex-col gap-2 font-bold text-sm p-5'>
                        <div>Buy Tax:</div>
                        <div>0%</div>
                    </div>
                    <div className='flex flex-col gap-2 font-bold text-sm p-5'>
                        <div>Sell Tax::</div>
                        <div>0%</div>
                    </div>
                </div>

            </div>
            <div className="absolute top-20 right-0 -z-50">
                <img src={hexes2} alt="Dots Circle" className="w-auto h-auto max-w-none transform translate-x-100%" />
            </div>
        </div>
    )
}
