import React, { useState } from 'react'

import dots_circle from '../assets/background/dots_circle.svg'
import one_hex from '../assets/background/one_hex.svg'


export default function WinInGameSection() {


    return (
        <div className="wrapper 2xl:pb-0 md:pb-56 pb-36">
            <div className="content-container md:h-[45vh] h-full">
                <div className='flex justify-between flex-col mt-24'>
                    <div className='font-bold md:text-6xl text-4xl uppercase text-center'>
                        <span className='text-[#4D9636] mr-3'>
                            Win
                        </span>
                        In-Game
                        <span className='text-[#4D9636] ml-3'>
                            $sPEPE
                        </span>
                        <br/>
                        Rewards
                    </div>

                </div>
                <div className='flex justify-center md:flex-row flex-col gap-2 mt-16 px-[20%]'>
                    <div className='button mb-4 !bg-[#E99138] !border-[#E99138]' style={{boxShadow:'0px 25px 50px -12px #E99138'}}>
                      <a href="https://whitepaper.spepe.app/spepe-community/how-to-buy" target="_blank" rel="noopener noreferrer">HOW TO BUY</a>
                    </div>
                    <div className='button mb-4'>
                        <a href="https://presale.spepe.app" target="_blank" rel="noopener noreferrer">BUY $SPEPE</a>
                    </div>
                </div>

                <div className="absolute top-20 right-12 -z-50">
                    <img src={one_hex} alt="Dots Circle" className="w-auto h-auto max-w-none transform translate-x-100%" />
                </div>
                <div className="absolute top-20 -left-11 -z-50">
                    <img src={dots_circle} alt="Dots Circle" className="w-auto h-auto max-w-none transform translate-x-100%" />
                </div>
                <div className="absolute bottom-10 left-210 -z-50">
                    <img src={one_hex} alt="Dots Circle" className="w-auto h-auto max-w-none transform translate-x-100%" />
                </div>
            </div>
        </div>
    )
}
