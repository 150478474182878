import React, { useState } from 'react'

import dots_circle from '../assets/background/hexes2.svg'
import star from '../assets/background/star.svg'
import one_hex from '../assets/background/one_hex.svg'
import morgan from '../assets/team/morgan.svg'
import ford from '../assets/team/ford.svg'
import guevara from '../assets/team/guevara.svg'
import rockefeller from '../assets/team/rockefeller.svg'


export default function TeamSection() {


    return (
        <div className="wrapper md:mt-36 mt-0 2xl:pb-0 md:pb-56 pb-0">
            <div className="content-container md:h-[100vh] h-full" >
                <div className='flex justify-between flex-col mt-24'>
                    <div className='font-bold text-4xl uppercase'>
                        Meet The Team
                    </div>

                </div>
                <div className='flex md:flex-row flex-col mt-12 gap-7'>
                    <div className='flex flex-col md:w-1/4 w-full'>
                        <img src={morgan} className='mb-12' />
                        <div className='flex-col flex gap-2 md:items-start items-center'>
                            <div className='flex  mb-1 text-3xl font-semibold'>J.Pepé Morgan</div>
                            <div className='text-[#FFFFFF99] font-medium'>CEO</div>
                        </div>
                    </div>
                    <div className='flex flex-col md:w-1/4 w-full'>
                        <img src={rockefeller} className='mb-12' />
                        <div className='flex-col flex gap-2 md:items-start items-center'>
                            <div className='flex mb-1 text-3xl font-semibold'>J.Pepé Rockefeller</div>
                            <div className='text-[#FFFFFF99] font-medium'>COO</div>
                        </div>
                    </div>
                    <div className='flex flex-col md:w-1/4 w-full'>
                        <img src={ford} className='mb-12' />
                        <div className='flex-col flex gap-2 md:items-start items-center'>
                            <div className='flex mb-1 text-3xl font-semibold'>Pepé Ford</div>
                            <div className='text-[#FFFFFF99] font-medium'>CTO</div>
                        </div>
                    </div>
                    <div className='flex flex-col md:w-1/4 w-full'>
                        <img src={guevara} className='mb-12' />
                        <div className='flex-col flex gap-2 md:items-start items-center'>
                            <div className='flex mb-1 text-3xl font-semibold'>Pepé Guevara</div>
                            <div className='text-[#FFFFFF99] font-medium'>CMO</div>
                        </div>
                    </div>
                </div>

                <div className="absolute top-20 right-12 -z-50">
                    <img src={one_hex} alt="Dots Circle" className="w-auto h-auto max-w-none transform translate-x-100%" />
                </div>
                <div className="absolute bottom-20 right-5 rotate-180 -z-50">
                    <img src={one_hex} alt="Dots Circle" className="w-auto h-auto max-w-none transform translate-x-100%" />
                </div>
                <div className="absolute top-20 left-11 rotate-45 -z-50">
                    <img src={dots_circle} alt="Dots Circle" className="w-auto h-auto max-w-none transform translate-x-100%" />
                </div>
                <div className="absolute bottom-10 left-10 rotate-45 -z-50">
                    <img src={one_hex} alt="Dots Circle" className="w-auto h-auto max-w-none transform translate-x-100%" />
                </div>
                <div className="absolute bottom-4 left-10 rotate-45 -z-50">
                    <img src={star} alt="Dots Circle" className="w-auto h-auto max-w-none transform translate-x-100%" />
                </div>
            
            </div>
        </div>
    )
}
