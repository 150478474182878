import React, { useEffect, useState } from 'react'
import hero_pepe from '../assets/background/hero_pepe.svg'
import '../App.css'
import logo from '../assets/logo.svg'
import btc from '../assets/tokens/btc.svg'
import sol from '../assets/tokens/Solana.svg'
import usdt from '../assets/tokens/usdt.svg'
import eth from '../assets/tokens/eth.svg'
import bnb from '../assets/tokens/bnb.svg'
import { useWindowSize } from './hooks/useWindowSize'


export default function HeroSection() {
    const [progress, setProgress] = useState(0); // State for progress
    const [soldToken, setSoldToken] = useState(0); // State for sold tokens
    const [totalToken, setTotalToken] = useState(0); // State for total tokens
    const [soldAmount, setSoldAmount] = useState('0 USD'); // State for sold amount
    const size = useWindowSize();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch('https://presale.spepe.app/api/stage/full?secret=kvvFmLkKcqxAJzyOME1fIkvI', {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }

                const data = await response.json();
                setProgress(data.response.progress); // Set progress from API
                setSoldToken(data.response.sold_token); // Set sold tokens from API
                setTotalToken(data.response.total_token); // Set total tokens from API
                setSoldAmount(data.response.sold_amount); // Set sold amount from API
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, []);

    return (
        <div className="full-width-wrapper">
            <div className="content-container">
                <div className='flex md:flex-row flex-col justify-between mt-24'>
                    <div className='flex md:flex-col flex-row gap-10 md:w-1/2 w-full'>
                        <div className='font-bold md:text-6xl text-4xl'>$SPEPE presale <br /><span className='text-[#3DFF00]'>LIVE NOW!</span></div>
                        <img src={hero_pepe} className='md:w-[420px] w-[150px] hero_pepe' />
                    </div>
                    <div className='md:w-1/2 w-full  hero_container flex flex-col justify-between'>
                        <div className='flex flex-col md:gap-12 gap-5'>
                            <div className='flex justify-between items-center rounded-3xl bg-white/10 md:p-4 p-3 md:px-7 px-2'>
                                <div className='flex items-center gap-3'>
                                    <div className='bg-white/10 p-1 rounded-3xl'><img src={logo} /></div>
                                    <div className='flex flex-col '>
                                        <div className='text-sm text-white/50'>Current Stage</div>
                                        <div className='text-lg font-semibold'>Stage 1</div>
                                    </div>
                                </div>
                                <div className='flex flex-col  text-right'>
                                    <div className='text-sm text-white/50'>Total Raised:</div>
                                    <div className='text-lg font-semibold'>{soldAmount}</div> {/* Updated to use soldAmount */}
                                </div>
                            </div>
                            <div className="relative overflow-hidden rounded-3xl border border-white/10 p-4 px-7">
                                {/* Progress Bar Background */}
                                <div className="absolute inset-0"></div>

                                {/* Progress Bar Fill */}
                                <div className="absolute inset-y-0 left-0 w-[${((soldToken) / 4000000000) * 100}%] drop-shadow-sm bg-gradient-to-r from-[#3CEFA3] to-[#F6FC5A]"></div>

                                {/* Content */}
                                <div className="relative z-10 flex justify-between items-center">
                                    <div className="flex items-center gap-3">
                                        <div className="text-4xl font-semibold text-shadow">{((soldToken) / 4000000000) * 100}%</div> {/* Updated to calculate progress */}
                                        {size.width > 768 ? <div className="text-sm text-shadow">Total <br /> Sold</div>
                                            : ''}
                                    </div>
                                    <div className="flex flex-col text-right">
                                        <div className="text-sm text-white/50 text-shadow">Remaining:</div>
                                        <div className="text-lg font-semibold text-shadow">{(totalToken - soldToken).toLocaleString()}</div> {/* Updated to use totalToken */}
                                    </div>
                                </div>
                            </div>
                            <div className='flex justify-between md:flex-row flex-col'>
                                <div className='flex flex-col gap-1'>
                                    <div className='text-sm text-white/50'>Current price 1 $SPEPE:</div>
                                    <div className='text-lg font-semibold'>0.0003 USD</div>
                                </div>
                                <div className='flex flex-col items-center justify-center gap-1'>
                                    <div className='text-sm text-white/50'>Tokens sold:</div>
                                    <div className='text-lg font-semibold'>{soldToken.toLocaleString()}</div> {/* Updated to use soldToken */}
                                    <div className='flex items-center gap-2'>
                                        <img src={sol} />
                                        <img src={usdt} />
                                    </div>
                                </div>
                                <div className='flex flex-col items-end gap-1'>
                                    <div className='text-sm text-white/50'>Next stage:</div>
                                    <div className='text-lg font-semibold'>0.0005 USDT</div>
                                </div>
                            </div>
                        </div>
                       <div className='mt-2'>
                            <div className='button mb-4'>
                                <a href="https://presale.spepe.app" target="_blank" rel="noopener noreferrer">Buy $SPEPE</a>
                            </div>
                            <div className='flex gap-3 justify-between'>
                                <div className='button w-1/2'>
                                <a href="https://whitepaper.spepe.app/spepe-community/how-to-buy" target="_blank" rel="noopener noreferrer">How To Buy?</a>
                            </div>
                                <div className='button w-1/2'>
                                <a href="https://t.me/spepecommunity" target="_blank" rel="noopener noreferrer">Telegram Chat</a>
                            </div>
                            </div>
                        </div>
                    </div> 
                </div>

            </div>
        </div>
    )
}
